import React from "react"
import HomepageData from "./data/extendedhomepage.json"
import Styles from "./css/extendedhomepage.module.scss"
import {isChrome,isFirefox,isIeEdge,isSafari, getBrowser} from "@tightrope/lpscripts/browserdetect"
class ExtendedHomepage extends React.Component {
  constructor(props){
    super(props);
    this.state = {
      isChrome: isChrome(),
      isFirefox: isFirefox(),
      isIeEdge: isIeEdge(),
      isSafari: isSafari(),
      display: 'none',
      pageData: props.data,
      data: HomepageData
    }
  }

  headline(data){
    let params = new URLSearchParams(window.location.search);
    let hc = params.get("hc");
    let kw = params.get("kw");
    let bn = params.get("bn");
    let qsData = {};
    if(hc){
      qsData.headline = hc;
    }
    if(kw){
      qsData.subhead = kw;
    }
    if(bn){
      qsData.mainCTA = bn;
    }
    if(qsData){
      const queryData = Object.assign(data, qsData);
      this.setState({data: queryData});
    }
  }

  showBrowsers(browsers){
    let display = false;
    if(getBrowser() === 'ie' && browsers.includes('ie')){
      display=true;
    }
   else if(getBrowser() === 'firefox' && browsers.includes('firefox')){
      display=true;
    }
   else if(getBrowser() === 'chrome' && browsers.includes('chrome')){
      display=true;
    }
   else if(getBrowser() === 'edge' && browsers.includes('edge')){
      display=true;
    }
  else if (getBrowser() === "safari" && browsers.includes("safari")) {
          display = true;
    }
   else if(getBrowser() === 'other' && browsers.includes('other')){
      display=true;
    }
    if(display)
      this.setState({display: 'block'});
    else
      this.setState({display: 'none'});
  }

  componentDidMount() {
    this.props.data.then(data => {
      const instanceData = Object.assign(this.state.data, data);
      this.setState({ data: instanceData});
    this.state.isChrome = isChrome();
    this.state.isFirefox = isFirefox();
    this.state.isIeEdge = isIeEdge();
    this.state.isSafari = isSafari();

    if(isChrome()){
      const browserData = Object.assign(this.state.data, this.state.data.chrome);
      this.setState({data: browserData});
    }
    if(isFirefox()){
      const browserData = Object.assign(this.state.data, this.state.data.firefox);
      this.setState({data: browserData});
    }
    if (isSafari()) {
        const browserData = Object.assign(this.state.data, this.state.data.safari);
        this.setState({ data: browserData });
    }
    if (isIeEdge()) {
        const browserData = Object.assign(this.state.data, this.state.data.edge);
        this.setState({ data: browserData });
    }
        this.headline(this.state.data);
    if(this.props.browsers){
      this.showBrowsers(this.props.browsers);
    }
    else{
      this.setState({display: 'block'});
    }
    });
  }

  render() {
    let intro;
    if (this.state.data.introType === 'video') {
      intro = <video className={Styles.centerimage} loop autoPlay muted><source src={this.state.data.introMedia}></source></video>;
    }
    else if (this.state.data.introType === 'image') {
      intro = <img src={this.state.data.introMedia} className={Styles.centerimage} />;
    }
    else{
      intro = null;
    }
    let second;
    if (this.state.data.secondType === 'video') {
      second = <video className={Styles.secondVideo} loop autoPlay muted><source src={this.state.data.secondMedia}></source></video>;
    }
    else if (this.state.data.secondType === 'image') {
      second = <img src={this.state.data.secondMedia} className={Styles.centerimage} />;
    }
    else{
      second = null;
    }
    return (
      <div className = {Styles.extendedhomepage}  style={{ display: this.state.display }} >
      <div className = {Styles.allContainer}>
        <header><img src={this.state.data.logo}/>
      	  <p>{this.state.data.productType}</p>
      	</header>

      <div className={Styles.banner}>
        <div className={Styles.bannerLeft}>

          <h1 className={Styles.headline}>{this.state.data.productHeadline}</h1>
          <h3>{this.state.data.subhead}</h3>
          <button onClick={() => window.triggerInstall()} data-cy="cta"
                  className={`${Styles.st2} ${Styles.bn_param} ${Styles.cws_dl}`}><span
              dangerouslySetInnerHTML={{__html: this.state.data.mainCTA}}></span>
          </button>
          {this.props.children}
        </div>

      </div>
        <div className={Styles.middle}>
          <h2>{this.state.data.secondHeadline}</h2>
          <div className={Styles.middleInner}>
            <div className={Styles.tile}>
              <img src={this.state.data.tile1}></img>
              <h4>{this.state.data.tileTitle1}</h4>
              <h5>{this.state.data.tileDescrip1}</h5>
            </div>
            <div className={Styles.tile}>
              <img src={this.state.data.tile2}></img>
              <h4>{this.state.data.tileTitle2}</h4>
              <h5>{this.state.data.tileDescrip2}</h5>
            </div>
            <div className={Styles.tile}>
              <img src={this.state.data.tile3}></img>
              <h4>{this.state.data.tileTitle3}</h4>
              <h5>{this.state.data.tileDescrip3}</h5>
            </div>
          </div>
          {intro}
          <p dangerouslySetInnerHTML={{__html: this.state.data.description}}></p>
          <button onClick={() => window.triggerInstall()} data-cy="cta"
                  className={`${Styles.st2} ${Styles.bn_param} ${Styles.cws_dl}`}><span
              dangerouslySetInnerHTML={{__html: this.state.data.mainCTA}}></span>
          </button>
          {this.props.children}

        </div>
        {/*end middle*/}
        <div className={Styles.bottom}>

          <h2 className={Styles.headlineMid}> {this.state.data.thirdHeadline}</h2>
          <div className={Styles.twocolInner}>
            <div className={Styles.left}>
              <div className={Styles.howto}>
                <div className={Styles.flexWrap}>
                  <img src={this.state.data.bulletpoint}/>
                  <span>
                  <h4>{this.state.data.arrowTitle1}</h4>
                  <h6>{this.state.data.arrowDescrip1}</h6>
                </span>
                </div>
                <div className={Styles.flexWrap}>
                  <img src={this.state.data.bulletpoint}/>
                  <span>
                  <h4>{this.state.data.arrowTitle2}</h4>
                  <h6>{this.state.data.arrowDescrip2}</h6>
                </span>
                </div>
                <div className={Styles.flexWrap}>
                  <img src={this.state.data.bulletpoint}/>
                  <span>
                  <h4>{this.state.data.arrowTitle3}</h4>
                  <h6>{this.state.data.arrowDescrip3}</h6>
                </span>
                </div>
              </div>

            </div>

            <div className={Styles.right}>
              {second}
            </div>
          </div>
          <button onClick={() => window.triggerInstall()} data-cy="cta"
                  className={`${Styles.st2} ${Styles.bn_param} ${Styles.cws_dl}`}><span
              dangerouslySetInnerHTML={{__html: this.state.data.mainCTA}}></span>
          </button>
          {this.props.children}
        </div>
      </div>
        {this.props.footer}
      </div>
    )
  }
}

export default ExtendedHomepage
